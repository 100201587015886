import React from 'react';

import Layout from '../components/Layout';

const IndexPage = ({ pageContext }) => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>{pageContext.title}</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner" dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      </section>
    </article>
  </Layout>
);

export default IndexPage;
